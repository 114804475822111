import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private http: HttpClient,
    private httpService: HttpService
  ) { }

  getLastRefreshTime() {
    let params = new HttpParams();
    params = params.set('UserID', localStorage.getItem('uid'));
    params = params.set('FormID', String(536));
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.get(this.httpService.path + 'getGrid_Data', {
      params: params,
      headers: headers,
      observe: 'response'
    });
  }

  getMenu(type = 'web') {
    let params = new HttpParams();
    params = params.set('UserID', localStorage.getItem('uid'));
    params = params.set('type', type);
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.get(this.httpService.path + 'get_Menu', {
      params: params,
      headers: headers,
      observe: 'response'
    });
  }

  getFormData(reportID: number, data: {report: string, filters?: any, json?: any}) {
    const user = JSON.parse(localStorage.getItem('user'))[0];
    const body = {};
    body['FormID'] = '900' + user?.clientID;
    body['clientID'] = user?.clientID;
    body['UserID'] = localStorage.getItem('uid');
    body['Data'] = data;
    body['requesttype'] = 'form';
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.post(this.httpService.path + 'getreportdata', body, {
      headers: headers,
      observe: 'response'
    });
  }

  getBukUploadTemplate(reportID: number, data: {report: string, filters?: any, json?: any}) {
    const user = JSON.parse(localStorage.getItem('user'))[0];
    const body = {};
    body['FormID'] = '900' + user?.clientID;
    body['clientID'] = user?.clientID;
    body['UserID'] = localStorage.getItem('uid');
    body['Data'] = data;
    body['requesttype'] = 'load-template';
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.post(this.httpService.path + 'getreportdata', body, {
      headers: headers,
      observe: 'response'
    });
  }

  bulkUpload(reportID: number, data: {report: string, filters?: any, json?: any}) {
    const user = JSON.parse(localStorage.getItem('user'))[0];
    const body = {};
    body['FormID'] = '900' + user?.clientID;
    body['clientID'] = user?.clientID;
    body['UserID'] = localStorage.getItem('uid');
    body['Data'] = data;
    body['requesttype'] = 'validate-data';
    console.log(body);
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.post(this.httpService.path + 'getreportdata', body, {
      headers: headers,
      observe: 'response'
    });
  }

  getReportFilters(reportID: number, data: {report: string, filters?: any, json?: any}) {
    const user = JSON.parse(localStorage.getItem('user'))[0];
    const body = {};
    body['FormID'] = '900' + user?.clientID;
    body['clientID'] = user?.clientID;
    body['UserID'] = localStorage.getItem('uid');
    body['Data'] = data;
    body['requesttype'] = 'filters';
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.post(this.httpService.path + 'getreportdata', body, {
      headers: headers,
      observe: 'response'
    });
  }

  getReportData(reportID: number, data: {report: string, filters?: any, json?: any}) {
    const user = JSON.parse(localStorage.getItem('user'))[0];
    const body = {};
    body['FormID'] = '900' + user?.clientID;
    body['clientID'] = user?.clientID;
    body['UserID'] = localStorage.getItem('uid');
    body['Data'] = data;
    body['requesttype'] = 'web';
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('token')
    });
    return this.http.post(this.httpService.path + 'getreportdata', body, {
      headers: headers,
      observe: 'response'
    });
    // return this.http.post(this.httpService.path + 'getGrid_filtersdata', body, {
    //   headers: headers,
    //   observe: 'response'
    // });
  }

  exportExcel(
    data: {report: string, filters?: any, json?: any},
    // filters: {from: string, to: string, report: string, json?: Object|Array<any>},
    pager: {offset: number, fetch: number} = {offset: 0, fetch: -1},
    business: string = 'NextG',
    columns: Array<string> = [],
    fileName: string = 'export_file'
  ) {
    const user = JSON.parse(localStorage.getItem('user'))[0];
    const body = {};
    body['FormID'] = '900' + user?.clientID;
    body['clientID'] = user?.clientID;
    body['UserID'] = localStorage.getItem('uid');
    body['Data'] = data;
    body['Pager'] = pager;
    body['business'] = business;
    body['columns'] = columns;
    body['fileName'] = fileName;
    body['requesttype'] = 'limited';
    // return this.http.post(this.httpService.mobileAPIPath + '/download', body, {
    //   responseType: 'blob'
    // });
    return this.http.post(this.httpService.path + 'export_excel', body, {
      responseType: 'blob'
    });
  }

  queueDownload(
    data: {report: string, filters?: any, json?: any},
    pager: {offset: number, fetch: number} = {offset: 0, fetch: -1},
    business: string = 'NextG',
    columns: Array<string> = [],
    fileName: string = 'export_file'
  ) {
    const user = JSON.parse(localStorage.getItem('user'))[0];
    const body = {};
    body['FormID'] = '900' + user?.clientID;
    body['clientID'] = user?.clientID;
    body['UserID'] = localStorage.getItem('uid');
    body['Data'] = data;
    body['Pager'] = pager;
    body['business'] = business;
    body['columns'] = columns;
    body['fileName'] = fileName;
    body['requesttype'] = 'excel';
    return this.http.post(this.httpService.path + 'queue_download', body, {
      responseType: 'blob'
    });
  }
}
