import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../../services/http/http.service';
import { Globals } from '../../variables/globals';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    hasChildren: boolean;
    collapsed: boolean;
    children: RouteInfo[];
    roles: number[];
    link?: string;
}
// export let ROUTES: RouteInfo[] = [
//     {
//       path: 'dashboard/',
//       title: 'Dashboards',
//       icon: 'ni-chart-pie-35 text-primary',
//       class: '',
//       hasChildren: true,
//       collapsed: true,
//       children: [
//         { path: '/dashboard/powerbi-attendance', title: 'Attendance',  icon: 'ni-chart-pie-35 text-primary', class: '', hasChildren: false, collapsed: true, children: [], roles: [53] },
//         // { path: '/dashboard/powerbi-sale_analysis', title: 'Test BI',  icon: 'ni-chart-pie-35 text-primary', class: '', hasChildren: false, collapsed: true, children: [], roles: [53] },
//       ],
//       roles: [53]
//     },
//     {
//       path: 'reports/',
//       title: 'Reports',
//       icon: 'fas fa-table text-success',
//       class: '',
//       hasChildren: true,
//       collapsed: true,
//       children: [
//         { path: '/report/attendance', title: 'Attendance',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [53] },
//         { path: '/report/mtd-attendance', title: 'MTD Attendance',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [53] },
//         { path: '/report/store-onboarding', title: 'Store Onboarding',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [53] },
//         { path: '/report/sale', title: 'Sales',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [53] },
//         { path: '/report/checkin-checkout', title: 'Check IN - Check Out',  icon: ' text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [53] },
//       ],
//       roles: [53]
//     },
//     { path: '/gallery', title: 'Gallery',  icon: 'far fa-images text-info', class: '', hasChildren: false, collapsed: true, children: [], roles: [53] },
// ];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;
  user: any;
  path = '';
  trainingCollapsed = true;

  constructor(
    private router: Router,
    private httpService: HttpService,
    private globals: Globals
  ) {
    this.path = this.httpService.path;
    this.user = JSON.parse(localStorage.getItem('user'))[0];
  }

  ngOnInit() {
    const routes = this.globals.routes.length > 0 ? this.globals.routes : JSON.parse(localStorage.getItem('menu'));
    this.menuItems = routes.map((menuItem: RouteInfo) => {
      if (menuItem.hasChildren) {
        if (menuItem.roles.includes(parseInt(this.user.userRoleID))) {
          menuItem.children = menuItem.children.map((subMenuItem: RouteInfo) => {
            if (subMenuItem.roles.includes(parseInt(this.user.userRoleID))) {
              return subMenuItem;
            }
          }).filter(subMenuItem => subMenuItem !== undefined);
          return menuItem;
        }
      } else {
        if (menuItem.roles.includes(parseInt(this.user.userRoleID))) {
          return menuItem;
        }
      }
      console.log(menuItem);
    }).filter(menuItem => menuItem !== undefined);
    console.log(this.menuItems);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('uid');
    localStorage.removeItem('feathers-jwt');
    localStorage.removeItem('token');
    localStorage.removeItem('menu');
    this.router.navigate(['/login']);
  }
}
