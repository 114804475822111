import { Injectable } from '@angular/core';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    hasChildren: boolean;
    collapsed: boolean;
    children: RouteInfo[];
    roles: number[];
    link?: string;
}

@Injectable()
export class Globals {
    routes: RouteInfo[] = [];
}