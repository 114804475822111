import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { HttpService } from '../../services/http/http.service';
import { Globals } from '../../variables/globals';
import { ReportService } from 'src/app/services/report/report.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public focus;
  public listTitles: any[];
  public location: Location;
  user: any;
  path = '';
  collapsed = false;

  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    private httpService: HttpService,
    private globals: Globals,
    private reportService: ReportService
  ) {
    this.path = this.httpService.path;
    this.user = JSON.parse(localStorage.getItem('user'))[0];
    this.location = location;
  }

  ngOnInit() {
  }

  toggleSidebar() {
    console.log(window.matchMedia("(min-width: 768px)"));
    if (window.matchMedia("(min-width: 768px)").matches) {
      this.collapsed = !this.collapsed;
      document.getElementById('sidenav-main').style.display = this.collapsed ? 'none' : 'block';
      document.getElementById('data-area').style.marginLeft = this.collapsed ? '0px' : '250px';
    }
  }
  
  getTitle() {
    const routes = this.globals.routes.length > 0 ? this.globals.routes : JSON.parse(localStorage.getItem('menu'));
    this.listTitles = routes.filter(listTitle => listTitle);
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice( 2 );
    }
    if (!titlee.includes('/')) {
      titlee = '/' + titlee;
    }
    for (let item = 0; item < this.listTitles.length; item++) {
      if (titlee.includes(this.listTitles[item].path)) {
        if (this.listTitles[item].hasChildren) {
          let ret = this.listTitles[item].title + ' / ';
          for (let i = 0; i < this.listTitles[item].children.length; i++) {
            if (titlee == this.listTitles[item].children[i].path.slice(1)) {
              ret += this.listTitles[item].children[i].title;
              return ret;
            }
          }
        } else {
          return this.listTitles[item].title;
        }
      }
    }
    return 'Dashboard';
  }

  reload() {
    this.reportService.getMenu().subscribe(resp => {
      const menu = JSON.parse(resp.body[0]['Menu']);
      localStorage.setItem('menu', JSON.stringify(menu));
      window.location.reload(true);
    });
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('uid');
    localStorage.removeItem('feathers-jwt');
    localStorage.removeItem('token');
    localStorage.removeItem('menu');
    this.router.navigate(['/login']);
  }

}
