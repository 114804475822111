import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  // path = 'http://localhost:3030/';
  // path = 'http://reporting.api.nextgapex.com/api/CRUD_APi/';
  // reportingPath = 'http://reporting.api.nextgapex.com/api/Reporting_API/';
  enableSSL = true;
  // mobileAPIPath = (this.enableSSL ? 'https' : 'http') + '://naiplwebapps02.azurewebsites.net';
  // path = (this.enableSSL ? 'https' : 'http') + '://naiplwebapps01.azurewebsites.net/api/CRUD_APi/';
  // reportingPath = (this.enableSSL ? 'https' : 'http') + '://naiplwebapps01.azurewebsites.net/api/Reporting_API/';
  mobileAPIPath = (this.enableSSL ? 'https' : 'http') + '://api.nextgapex.com';
  path = (this.enableSSL ? 'https' : 'http') + '://api.nextgapex.com/api/CRUD_APi/';

  constructor(
    private http: HttpClient,
  ) { }

  fileUpload(data, location = '') {
    return this.http.post(this.path + 'upload' + location, data, {
      observe: 'response'
    });
  }
}
